import LearnMore from './learn_more';
import { useState } from "react";
import img1 from "../assets/img/SketchPnge8d1848c267cd63bfa470a7f4963ebeb3720aad8c1a148568f6439a39ec43411.png";
import img2 from "../assets/img/SketchPnge8d1848c267cd63bfa470a7f4963ebeb3720aad8c1a148568f6439a39ec43411.png";
import img3 from "../assets/img/SketchPngc955bdf1f688d0a78e1f44f8770cbcc711851adee2690c1d527117751846ddc2.png";
import classNames from 'classnames';
import ArrowDownIcon from "./icons/ArrowDownIcon";


function RctTokenSalse({ price, coinsRemain, ustRemain,  loading, timeRemain, pair, saleTokenInfo, currentNativeTokenWeight, currentSaleTokenWeight, isScheduled }) {
  const [showMore, setShowMore] = useState(false);
  function toggleShowMore() {
    if (showMore) {
      setShowMore(false)
    } else {
      setShowMore(true)
    }
  }
  return (
    <div className="layer14 flex-col mb-20 rct_card">
    <div className="mod8 flex-row">
      <div className="bd2 flex-col"></div>
      <span className="infoBox1">
       <span>Phase&nbsp;1</span>
       <br/>
       <span>RCT-UST LBP</span>
      </span>
      <div className="bd3 flex-col"></div>
    </div>
    <div className="mod4 flex-row justify-between">
      <div className="mod9 flex-col">
        <div className="TextGroup33 flex-col">
          <div className="wrap8 flex-col justify-between">
          <a href='https://coinhall.org/charts/terra/terra1mxd85ljdjcm7ddfh8qvnxx8tyggnh3zsahy7aa'>
            {
              <span className="txt10">{'$' +price}</span>
            }
            {' '} <img width="20" src={'https://miro.medium.com/fit/c/176/176/1*k4k9b5WhV97AXtWG21RaJQ.jpeg'}/><span className="txt12"> price chart</span></a>
            <span className="info12">RCT Current Price</span>
          </div>
        </div>
        <img
          className="pic5"
          src={img1}
        />
        <div className="TextGroup34 flex-col">
          <div className="box23 flex-col justify-between">
            {
              <span className="txt10">{`${Math.round(currentSaleTokenWeight)} : ${Math.round(currentNativeTokenWeight)}`}</span>
            }
            <span className="info12">RCT:UST&nbsp;Weight</span>
          </div>
        </div>
      </div>
      <div className="bd6 flex-col">
        <div className="group6 flex-col">
          <div className="mod10 flex-col">
            <div className="outer11 flex-col">
              <div className="group7 flex-col">

                
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mod11 flex-col justify-between">
        <div className="TextGroup35 flex-col">
          <div className="outer12 flex-col justify-between align-end">
            {
            //   isScheduled ? 
            // <span className="word20">--</span>
            //   :
            <span className="word20">{coinsRemain}</span>
            }
            <span className="word21">RCT&nbsp;Remaining</span>
          </div>
        </div>
        <img
          className="pic6"
          src={img2}
        />
         <div className="TextGroup34 flex-col">
          <div className="outer12 flex-col justify-between">
            {
            //   isScheduled ? 
            // <span className="word20">--</span>
            //   :
            <span className="word20">{ustRemain}</span>
            }
            <span className="word19">UST&nbsp;Remaining</span>
          </div>
        </div>
      </div>
    </div>
    {
      !showMore && 
      <div>
          <div className="mod12 flex-row"  onClick={toggleShowMore}>
      <div className="outer8 flex-col justify-center items-center">
        <div className="mod13 flex-row">
          <div className="ImageText12 flex-col">
            <div className="mod13 flex-row justify-between items-center">
              <div className="TextGroup36 flex-col">
                <span className="txt17">Learn more</span>
              </div>
              {/* <img
                className="layer27"
                src={img3}
              /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className='justify-center items-center mb-8'>
          <ArrowDownIcon />
      </div>
  </div>
      
    }
    
    <div className={classNames("outer4 flex-col", { 'isScheduled': isScheduled })}>
      <div className="bd16 flex-col align-center">
        {
          isScheduled ? 
        <span className="info14">Will be started in</span>
          :
        <span className="info14">Time&nbsp;Remaining</span>

        }
        <div className="word24">
            {timeRemain}
        </div>
        <div className="box24 flex-row">
          <span className="info19">Days</span>
          <span className="info20">Hours</span>
          <span className="txt16">Minutes</span>
        </div>
      </div>
    </div>
    {
      showMore && <LearnMore 
      pair={pair}
      toggleShowMore={toggleShowMore}
      saleTokenInfo={saleTokenInfo}/>
    }
  </div>
  );
}

export default RctTokenSalse;
