import phase2 from '../assets/images/phase2.png';
function launchTimeLine() {
    return (
        <div className="layer7 flex-col mt-10 mb-20 rct_card">
            <div className="group1 flex-col" >
              <div className="main10 flex-col">
                <div className="box14 flex-row justify-between">
                  <div className="layer8 flex-col"></div>
                  <span className="word3">Launch&nbsp;Timeline</span>
                  <div className="layer9 flex-col"></div>
                </div>
                <div className="main16 flex-row">
                  <div className="section21 flex-col"></div>
                  <img
                    className="main15"
                    src={phase2}
                    alt=""
                  />
                  <div className="wrap14 flex-col"></div>
                </div>
                <div className="bd17 flex-row">
                  <div className="ImageText13 flex-col">
                    <div className="bd18 flex-row justify-between">
                      <div className="box27 flex-col"></div>
                      <div className="TextGroup39 flex-col">
                        <div className="layer39 flex-col justify-between">
                          <span className="word94">Phase&nbsp;1</span>
                          <span className="txt48">
                            RCT-UST LBP 
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ImageText14 flex-col">
                    <div className="box28 flex-row justify-between">
                      <div className="wrap11 flex-col"></div>
                      <div className="TextGroup40 flex-col">
                        <div className="main11 flex-col justify-between">
                          <span className="word95">Phase&nbsp;2</span>
                          <span className="info46">
                            Convertdrop
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ImageText15 flex-col">
                    <div className="wrap12 flex-row justify-between">
                      <div className="main12 flex-col"></div>
                      <div className="TextGroup41 flex-col">
                        <div className="main13 flex-col justify-between">
                          <span className="info47">Phase&nbsp;3</span>
                          <span className="txt49">After Big Bang</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bd19 flex-row">
                  <span className="word96">DAYS</span>
                  <img
                    className="pic9"
                    alt=""
                    src="./img/SketchPnge592ccbfe19a2d21913bbd99b54c6d5151a672495709fd2a0fd7a06b103ceeeb.png"
                  />
                  <span className="word97">DAYS</span>
                  <img
                    className="img3"
                    alt=""
                    src="./img/SketchPng0843de825783b581d2f9de19a0be131a0b785a409de5e4216c4a787065c533eb.png"
                  />
                  <span className="txt50">DAYS</span>
                  <img
                    className="img4"
                    alt=""
                    src="./img/SketchPng50ba9c1e6ed09525f89df282c364f55cc712d12bf72f23807886244c6300cbbe.png"
                  />
                </div>
                <div className="bd20 flex-row">
                  <div className="layer40 flex-col justify-between">
                    <div className="group16 flex-col">
                      <span className="info64 mt-1.5">3</span>
                    </div>
                    <span className="info48">Apr/28/22</span>
                  </div>
                  <div className="bd23 flex-col"><span className="info65 mt-1.5">2</span></div>
                  <div className="bd24 flex-col">
                    <span className="word121 mt-1.5">1</span>
                  </div>
                  <div className="ImageText16 flex-col">
                    <div className="box29 flex-col justify-between">
                      <div className="outer20 flex-col">
                        <div className="layer43 flex flex-row justify-between items-center mt-1">
                          <span className="word122">7</span>
                          <span className="txt58">6</span>
                          <span className="info66">5</span>
                          <span className="info67">4</span>
                          <span className="word123">3</span>
                          <span className="word124">2</span>
                          <span className="txt59">1</span>
                        </div>
                      </div>
                      <div className="TextGroup42 flex-col">
                        <span className="word104">May/01/22</span>
                      </div>
                    </div>
                  </div>
                  <div className="ImageText17 flex-col">
                    <div className="outer17 flex-col justify-between">
                      <div className="mod18 flex-col"></div>
                      <div className="TextGroup43 flex-col">
                        <span className="info50">May/08/22</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    )
}
export default launchTimeLine