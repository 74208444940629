import WeightsCart from './current_weight_card/weights_chart';
import img3 from "../assets/img/SketchPngc955bdf1f688d0a78e1f44f8770cbcc711851adee2690c1d527117751846ddc2.png";
import ArrowDownIcon from "./icons/ArrowDownIcon";

function learnMore({pair, saleTokenInfo, toggleShowMore}) {
    return ( 
        <div className='learnMoreBlock'>
            <div className="chakra-stack css-1b3eak1">
                <p className="chakra-text css-0">We are using&nbsp;
                    <a 
                        target="_blank" 
                        rel="noopener" 
                        className="chakra-link css-q9y1zy" 
                        href="https://github.com/astroport-fi/astroport-lbport">
                            Astroport's LBP contracts&nbsp;
                    </a> 
                    for Phase-1 to guarantee fairness and security. 
                    During this 3-day event, you will be able to get your hands on $RCT tokens.
                    After LBP is finished, Reactor will withdraw 70% of funds, 30% will be transferred into Astroport as RCT-UST pool.
                </p>
                <p className="chakra-text css-0">
                    <strong>UST gained from Phase-1 would become Reactor’s strategic fund mainly used to increase eligible veToken as $ASTRO, $ANC, and tokens of other potential projects.</strong>
                </p>
                <p className="chakra-text css-0">The Reactor Token Launch Phase 1 will have the following key parameters:</p>
                <ul role="list" className="css-1ms9pr9"
                    ><li className="css-0">Start Date: Apr 28th 2PM UTC</li>
                    <li className="css-0">Duration:&nbsp;3 days (72 hours)</li>
                    <li className="css-0">Initial RCT:&nbsp;2,500,000</li>
                    <li className="css-0">Initial UST:&nbsp; 600,000</li
                    ><li className="css-0">Start Weights:&nbsp;RCT 96%: UST, 4%</li>
                    <li className="css-0">End Weights:&nbsp;RCT, 50%: UST, 50%</li>
                </ul>
            </div>
            <WeightsCart 
                pair = { pair }
                saleTokenInfo = { saleTokenInfo }
            /> 
            <div className="mod12 flex-row" onClick={toggleShowMore}>
                <div className="outer8 flex-col justify-center items-center">
                    <div className="mod13 flex-row">
                    <div className="ImageText12 flex-col">
                        <div className="mod13 flex-row justify-between items-center">
                        <div className="TextGroup36 flex-col" >
                            <span className="txt17">Minimize</span>
                        </div>
                        {/* <div className='layer27'></div> */}
                        {/* <img
                            className="layer27"
                            src={img3}
                            alt=""
                        /> */}
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div className='justify-center items-center mt-8'>
                <ArrowDownIcon style={{ transform: "rotate(180deg)" }} />
                </div>
        </div>
    )
}
export default learnMore