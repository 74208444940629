import React from "react";
import copy from "copy-to-clipboard";
import { useWallet } from "../../hooks/use_wallet";
import { NativeTokenBalances } from "../../hooks/use_balance";
import { ReactComponent as Phase2 } from "../../assets/images/phase2-icon.svg";
// import { ReactComponent as View } from "../../assets/images/view.svg";
import { ReactComponent as Copy } from "../../assets/images/copy.svg";
// import ViewIcon from '../icons/ViewIcon';
import ViewIconImg from '../../assets/images/terraFind.png';
const WalletInfoPopover = ({ onClose }) => {
  const { walletAddress, disconnectWallet } = useWallet();
  const balances = NativeTokenBalances();
  const copyAddress = () => {
    copy(walletAddress);
  };
  return (
    <div className="modal1 modal absolute w-96 top-20 right-0 px-9 py-4 text-xs z-10 font-santral">
      <button
        type="button"
        onClick={() => {
          onClose();
        }}
        className="ml-4 opacity-60 hover:opacity-100 transition-opacity text-xl absolute top-0 right-0 w-35 h-35 close-btn cursor-pointer"
        aria-label="Disconnect wallet"
      >
        &times;
      </button>
      <div className="flex flex-col ">
        <div className="text-lg text-left mb-4">In my wallet</div>
        <div className="flex justify-between my-4">
          <div flex={1} className="flex justify-between items-center">
            <Phase2 className="mr-4" />
            {/* <Image boxSize="8" src={icon} onClose alt="" /> */}
            <div className="flex flex-col justify-center items-start">
              <p className="text-2xl mb-1">UST</p>
              <p className="text-xs view-wallet-color">Terra</p>
            </div>
          </div>
          <div>
            <div className="flex items-center">
              <div className="text-xs view-wallet-color mr-2">In Wallet: </div>
              <p className="font-semibold text-lg">
                ${/* {fromTerraAmount(balance)} */}
                {balances.native_token === '0' ? '0.0000' :  balances.native_token}
              </p>
            </div>
            {/* <HStack justify="space-between">
                <Text flex={1} textStyle="small" variant="dimmed">
                  Price:{" "}
                </Text>
                <Text textStyle="small" variant="dimmed">
                  {fromTerraAmount(price)}
                </Text>
              </HStack> */}
          </div>
        </div>
        <div className="text-left my-4">
          <p className="text-lg mb-1">My Address</p>
          <p className="text-xs view-wallet-color">
            {/* {truncate(terraAddress, [16, 16])} */}
            {walletAddress.slice(0, 6)}...{walletAddress.slice(-6)}
          </p>
        </div>
        <div className="flex justify-between mb-6">
          <button onClick={copyAddress}>
            <div className="flex align-center justify-center">
              <Copy />
              {/* <div width="1.5rem" height="1.5rem" /> */}
              <p className="text-xs view-wallet-color ml-2">Copy Address</p>
            </div>
          </button>
          <a
            className="text-xs"
            href={`https://terrasco.pe/mainnet/address/${walletAddress}`}
          >
            <div className="flex align-center justify-center">
              {/* <ViewIcon width="22px" height="22px" fill="#F2F5FF" /> */}
              {/* <View /> */}
              <img src={ViewIconImg} width="22px"/>
              <p className="text-xs view-wallet-color ml-2">
                View on Terrasco.pe
              </p>
            </div>
          </a>
        </div>
      </div>
      <div mt="6" className="mb-4 disconect">
        <button
          // type="button"
          // variant="primary"
          className="rounded-3xl leading-10 cursor-pointer btn-hover w-full text-lg"
          onClick={() => {
            disconnectWallet();
          }}
        >
          Disconnect
        </button>
      </div>
    </div>
  );
};

export default WalletInfoPopover;
