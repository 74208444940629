import { useEffect, useState, useCallback } from "react";
import "./connected_wallet.css";
import { useWallet } from "../hooks/use_wallet";
import { useNetwork } from "../hooks/use_network";
import { networks } from "../config/networks";
import WalletInfoPopover from "./wallet/WalletInfoPopover";
import { getBalance } from "../terra/queries";
import { nativeTokenFromPair } from "../helpers/asset_pairs";
import { NATIVE_TOKEN_DECIMALS } from "../constants";
import { formatTokenAmount } from "../helpers/number_formatters";

function ConnectedWallet(data) {
  const { walletAddress } = useWallet();
  const { terraClient, network } = useNetwork();
  const [showWallet, setShowWallet] = useState(false);
  const [balances, setBalances] = useState({});

  const dateBalances = useCallback(async () => {
    if (walletAddress) {
      const nativeToken = "uusd"

      const balances = await Promise.all([
        getBalance(terraClient, nativeToken, walletAddress),
      ]);

      setBalances({
        native_token: formatTokenAmount(balances[0], NATIVE_TOKEN_DECIMALS, 2),
      });
    }
  }, [walletAddress]);

  useEffect(() => {
    dateBalances();
    let timer = undefined;
    if (walletAddress && !timer) {
      timer = setInterval(() => {
        dateBalances();
      }, 15 * 1000);
    }
    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [walletAddress,dateBalances]);

  return (
    <>
    
      <div
        className="flex items-center cursor-pointer connected-wallet"
        onClick={() => {
          setShowWallet(true);
        }}
      >
        
        {network.chainID !== networks.mainnet.chainID && (
          <small className="connected-wallet__chain-id">
            {network.chainID}
          </small>
        )}
        <div className="text-white bg-opacity-10 text-opacity-90  bg-btn-color rounded-3xl py-2 px-6 rounded-3xl flex items-center">
          <div className="pr-4 text-sm font-thin" >
            {walletAddress.slice(0, 6)}...{walletAddress.slice(-6)}
          </div>
          <div className=" left-border  text-sm pl-4 relative font-thin">
            {" "}
            UST {balances.native_token === '0' ? '0.00' : balances.native_token }{" "}
          </div>
          {/* <button
            type="button"
            className="ml-4 opacity-60 hover:opacity-100 transition-opacity"
            onClick={disconnectWallet}
            aria-label="Disconnect wallet"
          >
            &times;
          </button> */}
        </div>
      </div>
      {showWallet && (
        <WalletInfoPopover
          onClose={() => {
            setShowWallet(false);
          }}
        />
      )}
    </>
  );
}

export default ConnectedWallet;
