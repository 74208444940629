import classNames from 'classnames';
import AssetInput from './asset_input';
import img1 from '../assets/img/SketchPng295b0f0daa4caeec5df432e1ac9b428c5a036632a48c1d3ea2db7569c629afc7.png'
import img2 from '../assets/img/SketchPng517c19bf11164dbe13bc83cf87c43e641018731136f5be666e1612a47f800f6b.png'

import { ReactComponent as ReverseArrows } from '../assets/images/reverse-arrows.svg';

function SwapForm({
  onSubmit,
  fromAmount,
  fromUSDAmount,
  fromAssetSymbol,
  fromBalance,
  fromMin,
  fromMax,
  fromStep,
  onFromAmountChange,
  fromMaxClick,
  toAmount,
  toUSDAmount,
  toAssetSymbol,
  toBalance,
  toStep,
  onToAmountChange,
  error,
  canSubmit,
  onReverseAssets,
  isScheduled,
  children
}) {
  return (
    <form onSubmit={onSubmit} className="flex flex-col  " >
      <div className="flex  flex-grow justify-between grid grid-cols-12 ">
        <AssetInput
          className='col-span-5 h-124'
          label="From"
          amount={fromAmount}
          onAmountChange={onFromAmountChange}
          usdAmount={fromUSDAmount}
          assetSymbol={fromAssetSymbol}
          required={true}
          balanceString={fromBalance}
          maxClick={fromMaxClick}
          max={fromMax}
          min={fromMin}
          step={fromStep}
          isScheduled={isScheduled}
        />

        <div className="flex items-center justify-center  mt-3 col-span-2 bg-jiantou">
          <button type="button" onClick={onReverseAssets}>
            <span className="sr-only">Reverse assets</span>
            <img src={img1}/>
            {/* <ReverseArrows className="h-5 w-5 opacity-50 hover:opacity-100 transition-opacity" aria-hidden="true" /> */}
          </button>
        </div>

        <AssetInput
          className='col-span-5 h-124'
          label="To (estimated)"
          amount={toAmount}
          onAmountChange={onToAmountChange}
          usdAmount={toUSDAmount}
          assetSymbol={toAssetSymbol}
          balanceString={toBalance}
          step={toStep}
        />
        <img
          className="img2 col-span-12"
          src={img2}
        />

        { children }

      </div>
      {
        error &&
        <div className="bg-red-600 bg-opacity-50 text-white text-center my-4 p-2 col-span-12 rounded rounded-lg">{error}</div>
      }
      <div className='section19 flex justify-center items-center mb-10 '>
        <button
          type="submit"
          className={
            classNames(
              "py-2 rounded-lg w-full  transition word87", {
                'btn-primary hover:animate-pulse': canSubmit,
                'btn-disabled': !canSubmit
              }
            )
          }
          disabled={!canSubmit}>
          Swap
        </button>
      </div>
    </form>
  );
}

export default SwapForm;
