import classNames from 'classnames';
import Card from './card';
// import loading from '../assets/images/error_loading.png';
import React, { useState } from "react";

function ErrorCard({ title, message, img, errorType, className }) {
  const [isContinue, setIsContinue] = useState(true); //
  // const [isConnectWallet, setIsConnectWallet] = useState(false); // 选择链接钱包
  return(
    <div className="loading-div h-full  absolute w-full h-full flex justify-center flex-col items-center">
    <Card className={classNames('px-5 py-4  modal1 error-card flex justify-center absolute inset-auto flex-col items-center z-20 w-fit', className)}>
      <h2 className="font-bold mb-4 flex justify-center error-message">{title}</h2>
      <span className='text-xs red-color'>{message}</span>
      <div className='flex flex-col items-center justify-center w-2/5'>
          <img src={img}/>
          {
            errorType !== 'wallet' && errorType !== 'notBegin' &&
            <button
              className={classNames(
                "  px-10 rounded-lg  flex justify-center w-full items-center cursor-pinter connect-wallet btn-hover",
                className
              )}
              onClick={()=>{setIsContinue(true)}}
            >
              Continue
            </button>
          }
         
      </div>
      
    </Card>
    </div>
  );
}

export default ErrorCard;
