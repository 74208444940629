
import React, { useState} from "react";
import { connectExtension, EXTENSION_UNAVAILABLE } from '../../terra/extension';
import { useWallet } from '../../hooks/use_wallet';

const ConnectWalletModal = ({ onClose }) => {
  const [connecting, setConnecting] = useState(false);
  const { connectWallet } = useWallet();

  const connect = async() => {
    setConnecting(true);
    // console.log('链接钱包')
    try {
      const wallet = await connectExtension()
      setConnecting(false);
      connectWallet(wallet);
      // onClose();
    } catch({ reason }) {
      setConnecting(false);
      if(reason === EXTENSION_UNAVAILABLE ) {
        window.open('https://chrome.google.com/webstore/detail/terra-station/aiifbnbfobpmeekipheeijimdpnlpgpp');
      }
    }
  };

  return (
    <div className="modal1 modal2 absolute w-96 top-20 right-0 px-9 py-4 text-xs z-10">
      <div mt="10" spacing="10" align="stretch" className="flex flex-col">
        <div spacing="3" >
          <button
            disabled={connecting}
            className="rounded my-2"
            onClick={() => {
              connect();
            }}
          >
            Terra Station Extension
          </button>
        </div>
        {/* <div spacing="3" >
          <button
            className="rounded my-2"
            disabled={connecting}
            onClick={() => {
              connect();
            }}
          >
            Terra Station Mobile
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default ConnectWalletModal;
