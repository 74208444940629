import React, { useState } from "react";
import classNames from "classnames";
import WalletDisclaimerPopover from "./wallet/WalletDisclaimerPopover";
import ConnectWalletModal from "./wallet/ConnectWalletModal";


function ConnectWalletButton({ className }) {
  const [isDisclaimerPopover, setDisclaimerPopover] = useState(false); //
  const [isConnectWallet, setIsConnectWallet] = useState(false); // 选择链接钱包
  // const [isDisclaimerPopover, setDisclaimerPopover] = useState(false); // 钱包链接后

  return (
    <>
      <button
        className={classNames(
          "  px-8 rounded-lg  flex justify-center items-center cursor-pinter connect-wallet btn-hover",
          className
        )}
        onClick={()=>{setDisclaimerPopover(true)}}
        disabled={!!isConnectWallet}
      >
        Connect your wallet
      </button>
      {isDisclaimerPopover && <WalletDisclaimerPopover  onClose={()=>{setDisclaimerPopover(false)}}  onClick={()=>{
        setDisclaimerPopover(false);
        setIsConnectWallet(true)
      }} />}

      {isConnectWallet && <ConnectWalletModal onClose={()=>{setIsConnectWallet(false)}}  />}
    </>
  );
}

export default ConnectWalletButton;
