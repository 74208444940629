import { useEffect, useState, useMemo, useCallback } from "react";
import { nativeTokenFromPair, saleAssetFromPair } from "../helpers/asset_pairs";
import InfoCard from "./info_card";
import { getWeights, getPool } from "../terra/queries";
import fetchUSTExchangeRate from "../services/fetch_ust_exchange_rate";
import { formatUSD, formatNumber, formatTokenAmount } from "../helpers/number_formatters";
import { calcPrice } from "../terra/math";
import { useRefreshingEffect } from "../hooks/use_refreshing_effect";
import { durationString } from "../helpers/time_formatters";
import SwapCard from "./swap_card";
import { Int } from "@terra-money/terra.js";
import Card from "./card";
import CurrentWeightCard from "./current_weight_card";
import { useWallet } from "../hooks/use_wallet";
import { useNetwork } from "../hooks/use_network";
import DisconnectedSwapCard from "./disconnected_swap_card";
import PairDescription from "./pair_description";
import RctTokenSalse from "./rct_token_sales";
const REFRESH_INTERVAL = 30_000; // 30s

function CurrentTokenSale({ pair, saleTokenInfo, isScheduled, upDatePhaseStatus }) {
  const [nativeTokenWeight, setNativeTokenWeight] = useState();
  const [saleTokenWeight, setSaleTokenWeight] = useState();
  const [pool, setPool] = useState();
  const [ustExchangeRate, setUSTExchangeRate] = useState();
  const [secondsRemaining, setSecondsRemaining] = useState();
  const { walletAddress } = useWallet();
  const { terraClient } = useNetwork();
 
  const refreshPairInfo = useCallback(async () => {
    const pool = await getPool(terraClient, pair.contract_addr);
    setPool(pool);

    let nativeTokenWeight = parseInt(pool.assets[1].start_weight),
      saleTokenWeight = parseInt(pool.assets[0].start_weight);

    try {
      [nativeTokenWeight, saleTokenWeight] = await getWeights(
        terraClient,
        pair.contract_addr,
        nativeTokenFromPair(pair.asset_infos).info.native_token.denom
      );
    } catch (e) {}
    setNativeTokenWeight(nativeTokenWeight);
    setSaleTokenWeight(saleTokenWeight);

  }, [pair]);

  useRefreshingEffect(refreshPairInfo, REFRESH_INTERVAL, [refreshPairInfo]);

  useRefreshingEffect(async () => {
    try {
      const exchangeRate = await fetchUSTExchangeRate();
      setUSTExchangeRate(exchangeRate);
    } catch (e) {
      //
    }
  }, REFRESH_INTERVAL);

  const ustPrice = useMemo(() => {
    if (
      pool === undefined ||
      nativeTokenWeight === undefined ||
      saleTokenWeight === undefined
    ) {
      return;
    }

    return calcPrice({
      ustPoolSize: new Int(nativeTokenFromPair(pool.assets).amount),
      tokenPoolSize: new Int(saleAssetFromPair(pool.assets).amount),
      ustWeight: nativeTokenWeight,
      tokenWeight: saleTokenWeight,
      tokenDecimals: saleTokenInfo.decimals,
    });
  }, [pool, nativeTokenWeight, saleTokenWeight, saleTokenInfo.decimals]);

  const usdPrice = useMemo(() => {
    // Don't convert if ust price or exchange rate is null
    if (ustPrice === undefined || ustExchangeRate === undefined) {
      return;
    }
    
    return ustPrice;
  }, [ustExchangeRate, ustPrice]);

  useEffect(() => {
    let status = 2;
    let timeout;
    const tick = () => {
      let secondsRemaining;
      if (isScheduled) {
        secondsRemaining = pair.start_time - Math.floor(Date.now() / 1000);
      } else {
        secondsRemaining = pair.end_time - Math.floor(Date.now() / 1000);
      }
      setSecondsRemaining(secondsRemaining > 0 ? secondsRemaining : 0);

      // Since we only display the time left with minute granularity,
      // we only need to recalculate on the next whole minute
      const secondsToNextMinute = secondsRemaining % 60;
      const delay = secondsToNextMinute === 0 ? 60 : secondsToNextMinute;

      timeout = setTimeout(tick, delay * 1000);
      
      if ( secondsRemaining <= 3) {
        upDatePhaseStatus(status++);
        // if (isScheduled) {
        //   upDatePhaseStatus(2); // pahse1开始前-开始；开始-结束。之后重新去获取下数据
        // } else {
        //   upDatePhaseStatus(3); // 1. 未开始；2 开始了；3 结束
        // }
      }
    };

    tick();

    return () => clearTimeout(timeout);
  }, [pair]);

  return (
    <>
      <RctTokenSalse
        price={formatNumber(usdPrice, { maximumFractionDigits: 4 })}
        coinsRemain={
          pool &&
          formatTokenAmount(
            saleAssetFromPair(pool.assets).amount,
            saleTokenInfo.decimals,
            4
          )
        }
        ustRemain={
          pool &&
          formatTokenAmount(
            nativeTokenFromPair(pool.assets).amount,
            saleTokenInfo.decimals,
            4
          )
        }
        timeRemain={durationString(secondsRemaining)}
        loading={
          usdPrice === undefined ||
          pool === undefined ||
          secondsRemaining === undefined
        }
        pair={pair}
        saleTokenInfo={saleTokenInfo}
        currentNativeTokenWeight={nativeTokenWeight}
        currentSaleTokenWeight={saleTokenWeight}
        isScheduled={isScheduled}
      ></RctTokenSalse>
      <div>
        {/* <InfoCard className="col-span-3" label="Price" value={formatUSD(usdPrice)} loading={usdPrice === undefined} />
        <InfoCard className="col-span-3" label="Coins Remaining" value={pool && formatTokenAmount(saleAssetFromPair(pool.assets).amount, saleTokenInfo.decimals)} loading={pool === undefined} />
        <InfoCard className="col-span-3" label="Time Remaining" value={durationString(secondsRemaining)} loading={secondsRemaining === undefined} />
        <CurrentWeightCard
          className="col-span-3"
          loading={nativeTokenWeight === undefined}
          pair={pair}
          saleTokenInfo={saleTokenInfo}
          currentNativeTokenWeight={nativeTokenWeight}
          currentSaleTokenWeight={saleTokenWeight}
        /> */}

        {/* {
          walletAddress ?
            <SwapCard
              className="col-span-5 "
              pair={pair}
              saleTokenInfo={saleTokenInfo}
              ustExchangeRate={ustExchangeRate}
              ustPrice={ustPrice}
              onSwapTxMined={() => refreshPairInfo()}
            />
          :
            <DisconnectedSwapCard pair={pair} className="col-start-4 col-span-6" />
        } */}

        <SwapCard
          className="col-span-5 "
          pair={pair}
          saleTokenInfo={saleTokenInfo}
          ustExchangeRate={ustExchangeRate}
          ustPrice={ustPrice}
          onSwapTxMined={() => refreshPairInfo()}
          isScheduled={isScheduled}
        />

        {/* {
          // When the wallet is connected, the About card is displayed next to the swap card.
          // When the wallet is disconnected, the About info is displayed
          // in the Swap card
          // walletAddress && pair.description &&
          // <Card className="py-8 px-12 col-span-7">
          //   <h2 className="font-bold mb-8">About</h2>

            <PairDescription pair={pair} />
          </Card>
        } */}
      </div>
    </>
  );
}

export default CurrentTokenSale;
