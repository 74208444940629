import "./token_sales.css";
import { useEffect, useState } from "react";
import reportException from "../report_exception";
import ScheduledTokenSalesCard from "./scheduled_token_sales_card";
import PreviousTokenSalesCard from "./previous_token_sales_card";
import CurrentTokenSale from "./current_token_sale";
import { getLBPs, getPairInfo, getTokenInfo } from "../terra/queries";
import { saleAssetFromPair } from "../helpers/asset_pairs";
import ConnectWalletButton from "./connect_wallet_button";
import ConnectedWallet from "./connected_wallet";
import { ReactComponent as LoadingIndicator } from "../assets/images/loading-indicator.svg";
import { useWallet } from "../hooks/use_wallet";
import { useNetwork } from "../hooks/use_network";
// import { ReactComponent as Logo } from "../assets/images/logo.svg";
import LaunchTimeLine from "./launch_time_line";
import Navbar from "./nav/Navbar";
import Logo from "../assets/images/logo-reactor.png";
import Phase1end from "./phase1_end";

function sortLBPsAsc(lbps) {
  return lbps.sort((a, b) => a.start_time - b.start_time);
}

function sortLBPsDesc(lbps) {
  return lbps.sort((a, b) => b.start_time - a.start_time);
}

function TokenSales() {
  const [loading, setLoading] = useState(true);
  const [errorLoadingData, setErrorLoadingData] = useState(false);
  const [scheduledPairs, setScheduledPairs] = useState([]);
  const [previousPairs, setPreviousPairs] = useState([]);
  const [currentPair, setCurrentPair] = useState();
  const [saleTokenInfo, setSaleTokenInfo] = useState();
  const { walletAddress } = useWallet();
  const { terraClient, network } = useNetwork();
  const [phase1Status, upDatePhaseStatus] = useState(1); 

  useEffect(() => {
    // console.log('useeffect');
    // 重新获取lbps
    const fetchLBPs = async () => {
      try {
        // We only care about permitted/whitelisted pairs
        const lbps = (
          await getLBPs(terraClient, network.factoryContractAddress)
        ).filter((lbp) =>
          network.allowedPairContracts.includes(lbp.contract_addr)
        );

        const currentTime = Math.floor(Date.now() / 1000);

        setScheduledPairs(
          sortLBPsAsc(lbps.filter((lbp) => lbp.start_time > currentTime))
        );
        setPreviousPairs(
          sortLBPsDesc(lbps.filter((lbp) => lbp.end_time <= currentTime))
        );

        const currentPair = lbps.find(
          (lbp) => lbp.start_time <= currentTime && lbp.end_time > currentTime
        );
        // console.log("lbps", lbps, scheduledPairs);
        // If there's an ongoing sale,
        // fetch the detailed info for the pair
        // and the sale token info (name, symbol, decimals, etc.)
        if (currentPair) {
          setCurrentPair(
            await getPairInfo(terraClient, currentPair.contract_addr)
          );

          const saleTokenAddress = saleAssetFromPair(currentPair.asset_infos)
            .info.token.contract_addr;
            let salesTokenInfo = await getTokenInfo(terraClient, saleTokenAddress);
            // console.log('salesTokenInfo', salesTokenInfo);
          setSaleTokenInfo(salesTokenInfo);
        } else if(lbps[0]) {
          const saleTokenAddress = saleAssetFromPair(lbps[0].asset_infos)
          .info.token.contract_addr;
          // console.log('salesTokenInfo', saleTokenAddress);
          let salesTokenInfo = await getTokenInfo(terraClient, saleTokenAddress);
          setSaleTokenInfo(salesTokenInfo);
          setCurrentPair();
        } else {
          setCurrentPair();
          // 设置空对象
          
          setSaleTokenInfo(
            {
              decimals: '',
              name: "",
              symbol: "",
              total_supply: "",
            }
          )
        }
      } catch (e) {
        reportException(e);
        setErrorLoadingData(false);
      } finally {
        setLoading(false);
      }
    };

    fetchLBPs();
  }, [network, terraClient, phase1Status]);

  if (loading) {
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        <LoadingIndicator className="w-20 h-20" />
      </div>
    );
  } else if (errorLoadingData) {
    return (
      <div className="m-10 text-center text-red-500">
        Error connecting to node
      </div>
    );
  } else {
    return (
      <>
        <div className="wid100">
          <div className="nav-cont mx-auto flex justify-between items-center  flex-1 my-1 relative">
            <div className="flex justify-between items-center space-6">
              <img className="pageLogo" src={Logo} />
              <div className="ml-10">
                <a href="https://bigbang.reactor.money/">Launch Plan</a>
              </div>
              <div className="ml-10 webname">Active Phase</div>
            </div>
            {/* 
          {
            walletAddress ?
              <ConnectedWallet />
              :
              <ConnectWalletButton />
          } */}

            <Navbar
              Pair={currentPair ? currentPair : scheduledPairs[0]}
              saleTokenInfo={saleTokenInfo}
            ></Navbar>
          </div>
        </div>

        <div className="container mx-auto flex flex-col items-center">
          {/* <Navbar/> */}
          <LaunchTimeLine />
          {/* {
  currentPair &&
    <div className="flex justify-between items-center">
      <h1 className="token-sale-heading text-2xl font-bold">
        {saleTokenInfo.name} Token Sale
      </h1>
    </div>
} */}

          {/* {currentPair && (
            <CurrentTokenSale
              pair={currentPair ? currentPair : scheduledPairs}
              saleTokenInfo={saleTokenInfo}
            />
          )} */}
          {
            currentPair || scheduledPairs.length ?
              <CurrentTokenSale 
                pair={currentPair ? currentPair : scheduledPairs[0]} 
                saleTokenInfo={saleTokenInfo} 
                upDatePhaseStatus={upDatePhaseStatus}
                isScheduled={scheduledPairs.length > 0}/> :
           previousPairs.length ? <Phase1end></Phase1end> : ''
          }
          {/* {scheduledPairs.length > 0 && <ScheduledTokenSalesCard pairs={scheduledPairs} className="my-8" />} */}
          {/* {previousPairs.length > 0 && <PreviousTokenSalesCard pairs={previousPairs} className="my-8" />} */}
        </div>
      </>
    );
  }
}

export default TokenSales;
