import React, { useState } from "react";

const WalletDisclaimerPopover = ({onClick ,onClose}) => {
  const [checkedItems, setCheckedItems] = useState([false, false]);
  const allChecked = checkedItems.every(Boolean);

  return (
    <div className="flex flex-col modal1 absolute w-96 top-20 right-0 px-9 py-10 text-xs z-10">
      {/* <div className="close-btn absolute top-0 right-0" onClick={onClose}></div> */}
      <button
        type="button"
        onClick={() => {
          onClose();
        }}
        className="ml-4 opacity-60 hover:opacity-100 transition-opacity text-xl absolute top-0 right-0 w-35 h-35 close-btn cursor-pointer"
        aria-label="Disconnect wallet"
      >
        &times;
      </button>
      <div className="text-lg mb-1.5"> Disclaimer</div>
      <div  className="mb-5 text-left">
        By connecting a wallet, you agree to Reactor’s Terms of Service and
        acknowledge that you have read and understand the protocol’s
        disclaimers. Please check the boxes below to confirm your agreement to
        the{" "}
        <a
          href="https://docs.reactor.money/terms-of-service/disclaimers-disclosures"
        >
          Reactor Terms and Conditions
        </a>
      </div>
      <div mt="10" spacing="10" align="stretch">
        <div spacing="6" fontWeight="500" className="mb-5 flex flex-col ">
          <div className="mb-5 flex ">
            <label className="flex cursor-pointer">
            <input
              type="checkbox"
              className=" default:ring-2 opacity-75 mr-4 text-m"
              ischecked={checkedItems[0].toString()}
              onChange={(e) =>
                setCheckedItems([e.target.checked, checkedItems[1]])
              }
            />
            <div className="text-left">
                I have read and understood, and do hereby agree to be legally
                bound as a ‘User’ under, the Terms, including all future
                amendments thereto. Such agreement is irrevocable and will apply
                to all of my uses of the Site without me providing confirmation in
                each specific instance.
            </div>
            </label>
          </div>
          <div  className="flex ">
          <label className="flex cursor-pointer">
          <input type="checkbox" className=" default:ring-2 mr-4 text-s"  
            ischecked={checkedItems[1].toString()}
            onChange={(e) =>
              setCheckedItems([checkedItems[0], e.target.checked])
            }></input>  
        
            <div  className="text-left">
              I acknowledge and agree that the Site solely provides information
              about data on the Terra blockchain. I accept that the Site
              operators have no custody over my funds, ability or duty to
              transact on my behalf or power to reverse my transactions. The
              Site operators do not endorse or provide any warranty with respect
              to any tokens.
            </div>
            </label>
          </div>
        </div>
        <div
          spacing="3"
          className="my-5 text-center accpet-btn flex justify-center"
        >
          <button
            variant="primary"
            width="100%"
            className="cursor-pointer btn-hover rounded-3xl "
            onClick={() => {
              onClick();
            }}
            disabled={!allChecked}
          >
            Accept
          </button>
        </div>
      </div>
    </div>
  );
};

export default WalletDisclaimerPopover;
