import '../assets/phase1_end.css';
import phase1EndImg from '../assets/images/phase1_end.png';

function Phase1end() {
    return (
        <div className='phase1End'>
            <div className='phase1End_head'>
                <span>Phase&nbsp;1</span>
                <br/>
                <span>RCT-UST LBP</span>
            </div>
            <div className='phase1End_content'>Phase-1 has ended. Please wait for Phase-2.</div>
            <div className='phase1End_img'>
                <img src={phase1EndImg} />
            </div>
        </div>
    )
}
export default Phase1end