import React, { FC } from "react";
import ConnectWalletButton from "./../connect_wallet_button";
import ConnectedWallet from "./../connected_wallet";
import { useWallet } from "../../hooks/use_wallet";
import  Logo  from '../../assets/images/logo-reactor.png';

// import BurgerIcon from "components/icons/BurgerIcon";
// import CloseIcon from "components/icons/CloseIcon";
// import SocialMedia from "components/socialMedia";

const Navbar = (Pair,saleTokenInfo) => {
  // const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const { walletAddress } = useWallet();

  return (
     
    <div>
      <div w="100%" justify="space-between" align="center" className="connect-wrapper">
      {/* <div className="wid100 mt-6">
          <div className='nav-cont mx-auto flex justify-between  flex-1 my-1 relative'>
            <div className="flex justify-between items-center space-6">
              <img className="pageLogo" src={Logo}/>
              <div className="ml-4 webname">Launch Plan</div>
              <div className="ml-4 ">Active Phase</div>
            </div> 
          </div>
      </div>       */}
        {/* <div flexShrink={0}>
          <a href="/">
            <img src="/logo-reactor.png" alt="" height="48px" />
          </a>
        </div>
        <div display={["none", null, null, "block"]} flex="1">
          <div>
            <a href="/">
              <p>Launch Plan</p>
            </a>
          </div>
        </div> */}
        {/* <div spacing="5" justify="flex-end">
          <SocialMedia />
        </div> */}
        <div spacing="5" justify="flex-end" >
          {walletAddress ? <ConnectedWallet  data={{Pair,saleTokenInfo}}/> : <ConnectWalletButton />}
          <div display={[null, null, null, "none"]}>
            {/* <button variant="icon" ref={btnRef} onClick={onOpen} pr="0" mr="-2">
              <BurgerIcon color="white" width="1.5rem" height="1.5rem" />
            </button> */}
          </div>
        </div>
      </div>
      {/* <Drawer
        isOpen={isOpen}
        placement="left"
        size="sm"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <Flex
            height="100%"
            bg="brand.green"
            zIndex="100"
            px={["6", null, "12"]}
            py="8"
            direction="column"
          >
            <Flex justify="space-between" width="100%" align="center">
              <Box flexShrink={0}>
                <Image
                  src="/logo-astroport.svg"
                  alt="Astroport. The future of trading."
                />
              </Box>
              <Button variant="icon" mr="-2" onClick={onClose}>
                <CloseIcon color="white" width="1.5rem" height="1.5rem" />
              </Button>
            </Flex>
            <Box mt="20">
              <VStack spacing="4" align="flex-start" textTransform="uppercase">
                <Link href="/" color="white">
                  Launch Plan
                </Link>
              </VStack>
            </Box>
            <VStack spacing="10" align="flex-start" mt="auto">
              <TerraWallet />
              <HStack spacing="12" align="flex-start" display="none">
                <Link
                  href="https://astroport.fi/terms-and-conditions"
                  textTransform="uppercase"
                  color="white"
                  opacity="0.7"
                  isExternal
                >
                  Terms of use
                </Link>
              </HStack>
            </VStack>
          </Flex>
        </DrawerContent>
      </Drawer> */}
    </div>
  );
};

export default Navbar;
