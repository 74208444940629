// let envNetworks = {};

// if(process.env.NODE_ENV !== 'production') {
//   const envConfig = require(`./environments/${process.env.NODE_ENV}.js`);
//   envNetworks = envConfig.networks;
// }
export const networks = {
  mainnet: {
    lcdURL: 'https://lcd.terra.dev',
    chainID: 'columbus-5',
    factoryContractAddress: 'terra1ck0z4l2srfsr3muytm7eycf5jm0acfcvcqg9q0', // 4点测试
    gasPrices: { uusd: 0.15 },
    allowedPairContracts: [
      // Fill in with your project's pair contract(s)
      'terra1mxd85ljdjcm7ddfh8qvnxx8tyggnh3zsahy7aa' // 4点测试
    ]
  },
  testnet: {
    lcdURL: 'https://bombay-lcd.terra.dev',
    chainID: 'bombay-12',
    // factoryContractAddress: 'terra1u382c667y7u4flh3ywghhfzukql9jn2u08pz70',
    // factoryContractAddress: 'terra1sm92282n4muh550w930gcuf29z9h9t8v64t9cl', // 4月24号晚上开始的
    // factoryContractAddress: 'terra1dd65wey0w9k3j5mxnxmw7zwzs2n7zh7ja3xfes', // 晚上7点开始，9点结束，
    // factoryContractAddress: 'terra1d65hmj87tg2nem2waa83lgwxh76len0qucsug0', // 已经结束了的
    // factoryContractAddress: 'terra1m5ex7qxetw4cwvl8ecxndp6u66wynqq7fnz9c5', // 还没开始的
    // factoryContractAddress: 'terra1vfs4j253pdgm3s3w5c7edelcgj8k47lc8gsl3c', // 已经开始了的
    factoryContractAddress: 'terra1hf84ppcrmwfww62dhq8tnjhdmzf4teqsqxn8m8', // 2点测试
    gasPrices: { uusd: 0.15 },
    allowedPairContracts: [
      // 'terra133fsutxz39l0ulh46nhqtg6fddqnyv3qc49g4a'
      // 'terra1a6pmfd809kexklfy9v0kgua2p9zzqnkf5e37sw' // TESTA
      // 'terra1nqakwfz4l5rsqeunvfzntnjcq3wjcra9vvvfxu'
      // 'terra1dvxu4nt026jgv9h8lp4ll98uju342vn8z67x4p' // 已经结束了的
      // 'terra1g4c8rk5tk004rjf3dhlly6rklkypujfw77wtjf' // 还没开始的
      // 'terra1few69dpx535a9ehtzn975rzjrkkt4t506yezve' // 已经开始了的
      'terra1ynjv080tj0z4u3kw49aewsnh3c3629aj20wwa3' // 2点测试
    ]
  },
  // ...envNetworks
};

export const defaultNetwork = networks[process.env.REACT_APP_DEFAULT_NETWORK];
