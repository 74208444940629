export function timeString(timeMs) {
  const date = new Date(timeMs);

  return date.toLocaleString(undefined, {
    hour: '2-digit',
    minute: '2-digit',
  })
}

export function dateString(date) {
  return date.toLocaleString(undefined, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  })
}

export function timeAndDateString(timeMs) {
  const date = new Date(timeMs);

  return date.toLocaleString(undefined, {
    timeZoneName: 'short',
    hour: '2-digit',
    minute: '2-digit',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  })
}

// Returns a string like: 1d : 22h : 25m
// When seconds < 60, returns "< 1m"
// Behavior when seconds <=0 is unspecified
export function durationString(seconds) {
  const minutes = seconds / 60;
  const hours = minutes / 60;

  let hours_ = Math.floor(hours / 24);
  let minutes_ = Math.floor(hours % 24);
  let seconds_ = Math.floor(minutes % 60);
  let formatTime = str => {
    if (str < 10) {
      return '0' + str
    } 
    return str;
  }

  if(seconds >= 60) {
    return `${formatTime(hours_)} : ${formatTime(minutes_)} : ${formatTime(seconds_)}`;
  } else {
    return '00 : 00 : 00';
  }
}
