import { useState, useEffect, useCallback } from "react";
import { useNetwork } from "./use_network";
import { useWallet } from "./use_wallet";
import { NATIVE_TOKEN_DECIMALS } from "../constants";
import { nativeTokenFromPair } from "../helpers/asset_pairs";
import { formatTokenAmount } from "../helpers/number_formatters";
import { getBalance } from "../terra/queries";

const NativeTokenBalances = (pair) => {
  const [balances, setBalances] = useState({});
  const { terraClient } = useNetwork();
  const { walletAddress } = useWallet();

  const dateBalances = useCallback(async () => {
    if (walletAddress) {
      const nativeToken = "uusd"
      const balances = await Promise.all([
        getBalance(terraClient, nativeToken, walletAddress),
      ]);
      setBalances({
        native_token: formatTokenAmount(balances[0], NATIVE_TOKEN_DECIMALS, 4),
      });

    }
  }, [walletAddress]);

  useEffect(() => {
    dateBalances();
  }, [dateBalances,pair]);

  return balances;
};

export { NativeTokenBalances }